.tag {
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
  flex-flow: wrap;
  border: 1px solid rgb(212, 209, 209);
}

.selected {
  border: 1px solid rgb(172, 255, 196);
  background: rgb(223, 253, 231);
}
.tag:hover {
  background: rgb(223, 253, 231);
}
