.defaultBorder {
  border-radius: 4px;
}

.containerUploadImages {
  display: flex;
  justify-content: flex-start;
}

.title {
  margin-bottom: 10px;
}
