.flexTextarea {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.textarea textarea {
  width: 100%;
  height: 64px;
  resize: none;
  font-size: 14px;
}
