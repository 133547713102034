.descriptionButtons {
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}
.containerPushNotification {
  margin-top: 20px;
}
.checkbox {
  margin-bottom: 30px;
}
.list {
  min-width: 422px;
  margin-bottom: 20px;
}
.containerPhone {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
  margin-right: 2rem;
}
.phone {
  width: 190px;
  min-height: 411px;
  background-color: #030614;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.37);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.37);
}
.imagePhone {
  height: 73px;
  width: 190px;
}

.textPhoneList {
  color: #c7d1da;
  font-size: 7px;
  margin-left: 4px;
}

.textPhoneParagraph {
  color: #fff;
  font-size: 7px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.buttonPhone {
  height: 20px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #1ab21e;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
  font-size: 7px;
  margin-top: 18px;
  margin-bottom: 20px;
}

.empty {
  height: 50;
}

.imgList {
  width: 40px;
  height: 40px;
}

.containerText {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 8px;
}

.titlePhone {
  color: white;
  font-size: 9px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 4px;
  margin-top: 7px;
}

.containerInAppDetails {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
}
