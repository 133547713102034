.form div {
  margin-bottom: 3px;
}

.descriptionButtons {
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}