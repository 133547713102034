.titleCol {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  & .title {
    margin-bottom: 0;
    flex-shrink: 0;
  }
}


.wrapper {
  width: 100%;
}