.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 1rem;
}

.actions {
  display: flex;
  gap: 1rem;
}
