.title {
  margin-bottom: 10px;
}

.table {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  grid-template-columns: 4rem 5rem 8rem;
  justify-content: space-between;
  justify-items: center;
}

.prettyJson {
  padding: 25px;
}

.prettyJson > div {
  margin-bottom: 8px;
}