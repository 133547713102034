
.title {
  margin-bottom: 10px;
  width: 100%;
}
.titleWithoutMargin {
  width: 100%;
}
.defaultBorder {
  border-radius: 4px;
}
.containerUploadImages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.selectItemWrapper {
  display: flex;
  justify-content: space-between;

  & .remaining {
    color: #9B9B9B;
    width: 80px
  }
}