.wrapper {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & .title {
    font-size: 24px;
  }

  & .column {
    display: flex;
    align-items: end;
    gap: 10px;

    & .select {
      width: 170px;
      flex-shrink: 0;
    }

    & button {
      flex-shrink: 0;
    }

    & label {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      max-width: 600px;
      width: 100%;
    }
  }
}
