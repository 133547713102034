.container {
  /* negative margins used as a hack - parent component sets a margin we don't want 
  and it's out of our control without big refactoring
  */
  margin: -5px -16px 0px;
  /* hack due to previous negative margin */
  height: calc(100% + 5px);
  display: flex;
  flex-direction: column;
  background-color: #F0F2F5;
}

.header {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 16px 24px;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px 48px;
}