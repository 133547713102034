.imageUploadWrapper {
  border: 1px dashed #d9d9d9;
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;

  > *:first-child {
    flex-grow: 1;
  }
}