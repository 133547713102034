.contentEditable {
  background-color: white
}

.markdownEditor {
  box-shadow: 0 0 0 1px #d9d9d9;
}

.rowWrapper {
  width: 100%;
  display: flex;
  gap: 8px;
  background-color: #fbfbfb;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 1px 1px 8px #00000021;
  flex-direction: column;
}