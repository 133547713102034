.wrapper {
  & .containerPhone {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
    margin-right: 2rem;
    min-width: 250px;
  }
  & .phone {
    min-height: 411px;
    padding: 15px 10px;

    background-color: #030614;
    min-width: 250px;
  }
  & svg {
    margin-right: 7px;

    transform: translate(0px, 3px);
  }
  & .text {
    font-weight: 600;
    font-size: 14px;
    margin-right: 7px;
    line-height: 20px;
  }

  & .button {
    font-size: 14px;
    padding: 0 10px;
    height: 26px;
  }
}
