.flex {
  display: grid;
  gap: 10px;
  margin-bottom: 14px;
}

.flex1 {
  display: grid;
  gap: 10px;
  margin-bottom: 14px;
}
.flexTextarea {
  display: grid;
  gap: 10px;
  margin-bottom: 30px;
}

.flex > span > div {
  width: 470px !important;
  height: 58px !important;
  margin-top: 10px;
}

.grid {
  display: grid;
  grid-template-columns: 230px 1fr;
  gap: 10px;
}

.subgrid {
  display: grid;
  grid-template-columns: auto 102px;
  align-content: space-between;
}
.subgrid div:first-child {
  text-align: start;
}
.subgrid div:nth-child(2) {
  width: 100px;
}
.platforms {
  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
}
.platforms span {
  padding-right: 5px;
  padding-left: 0;
}

.textarea textarea {
  width: 100%;
  height: 64px;
  resize: none;
  font-size: 14px;
}
