.fullHeightCard {
  height: 100%;
}

.cardHeadingWithTooltip {
  display: flex;
  align-items: center;
  gap: 4px;
  
}

.icon {
  cursor: help;
  color: rgba(0, 0, 0, 0.45)
}

.imageUploadWrapper {
  border: 1px dashed #d9d9d9;
  padding: 8px;
  display: flex;
  gap: 8px;
  align-items: center;

  > *:first-child {
    flex-grow: 1;
  }
}

.wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
}

.title {
  padding: 0px 12px;
  margin: 0;
  background-color: #fafafa;
  height: 48px;
  display: flex;
  align-items: center;
}

.mainSpace {
  width: 100%;
}