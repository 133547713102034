.containerUploadImages {
  flex-flow: wrap;
  display: flex;
  gap: 0 20px;
}
.descriptionButtons {
  display: grid;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  grid-auto-flow: column;
}
.containerUploadImages span {
  grid-row: 2;
}

.redText {
  color: red;
  position: absolute;
  top: -20px;
}
