body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.avatar-uploader-file {
  border: 1px solid;
  cursor: pointer;
  display: grid;
}
.avatar-uploader-file .ant-upload-text {
  padding: 1rem;
}

.ant-drawer-body::-webkit-scrollbar {
  width: 5px;
}

.ant-drawer-body::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: rgb(248, 248, 248);
}

.ant-drawer-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(151, 151, 151);
}
/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); */
.toast-container {
  z-index: 100000 !important;
}
.toast-progress-line {
  height: 4px !important;

  background: white !important;
}

.toast-error {
  background: darkred !important;

  z-index: 100000 !important;
}
.toast-error .error-body {
  display: flex !important;
  align-items: center !important;

  color: white !important;
}
.toast-error .error-body::before {
  margin-right: 1rem;

  content: url('./assets/error.svg');
  filter: invert(1) !important;
}

.toast-success {
  background: #43bc22 !important;

  z-index: 100000 !important;
}
.toast-success .success-body {
  display: flex !important;
  align-items: center !important;

  color: white !important;
}

.toast-success .success-body::before {
  margin-right: 1rem !important;

  content: url('./assets/success.svg') !important;
  filter: invert(1) !important;
}

.toast-bonus {
  background: #000 !important;

  border: 1px solid #43bc22 !important;

  z-index: 100000 !important;
}

.toast-bonus .bonus-body {
  display: flex !important;
  align-items: center !important;

  color: white !important;
}

.toast-bonus .bonus-body::before {
  margin-right: 1rem !important;

  content: url('./assets/warn.svg');
  filter: invert(1) !important;
}

.toast-info {
  background: #000 !important;

  border: 1px solid #43bc22 !important;

  z-index: 1000000 !important;
}

.toast-info .info-body {
  display: flex !important;
  align-items: center !important;

  color: white !important;
}

.toast-info .info-body::before {
  margin-right: 1rem !important;

  content: url('./assets/info.svg');
  filter: invert(1) !important;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: text-top;
}

.mainMenu a {
  font-size: 14px;
}
.mainMenu .ant-menu-item,
.mainMenu .ant-menu-submenu {
  height: 35px !important;
  margin-bottom: 0 !important;
}
.mainMenu .ant-menu-submenu-title {
  line-height: 35px !important;
  height: 35px !important;
}
.mainMenu .ant-menu-submenu-open {
  height: auto !important;
}

.mainMenu .ant-menu-inline {
  margin-top: 5px !important;
}
.mainMenu path {
  fill: #1890ff;
}

.mainMenu .ant-menu-item-selected {
  background-color: inherit !important;
  border-right: 4px solid #1890ff !important;
}

.mainMenu .ant-menu-submenu-title,
.mainMenu .ant-menu-item {
  padding-left: 10px !important;
}
.mainMenu .ant-menu-item a {
  color: #1890ff !important;
}

.mainMenu .ant-menu-item-only-child a {
  color: #a3a3a3 !important;
}

.mainMenu .ant-menu-item-only-child a:hover {
  color: #1890ff !important;
}
.mainMenu .ant-menu-item-selected a {
  color: #1890ff !important;
}

.mainMenu .ant-menu-submenu-title:hover path,
.mainMenu .ant-menu-item:hover path {
  fill: #fff !important;
  transition: border-color 0.3s, background 0.3s, fill 0.3s,
    padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}
.mainMenu .ant-menu-item:hover a {
  color: #fff !important;
}

.mainMenu .ant-menu-item-only-child {
  padding-left: 42px !important;
}

.mainMenu .ant-menu-item-icon {
  transform: translate(0px, -1px);
}
.ant-menu,
.ant-layout-sider {
  background: #262626 !important;
}

.mainMenu .ant-menu-item svg,
.mainMenu .ant-menu-submenu svg {
  transform: scale(1.1) !important;
}
#raffleTable ul {
  margin: 7px 0;

  list-style: none;
  padding-inline-start: 0;
}
#raffleTable ul li {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  align-items: center;

  text-transform: capitalize;
}

#raffleTable ul li::before {
  position: relative;

  display: inline-block;

  height: 6px;
  width: 6px;
  margin-right: 8px;

  border-radius: 100%;

  content: '';
}
.activeStatus li::before {
  background: #52c41a;
}
.processingStatus li::before {
  background: #d9d9d9;
}
.anotherStatus li::before {
  background: #ff4d4f;
}

.finishedStatus li::before {
  background: #2f54eb;
}

#raffleTable .ant-table-tbody {
  background: #f0f0f0;
}

.site-layout {
  background: #fff;
}

.avatar-uploader {
  display: grid;
}

#createRaffle .ant-form-item-row {
  display: grid;
  grid-template-columns: 1fr 320px;
  width: 100%;
  place-items: self-start;
  margin-bottom: 16px;
}

#createRaffle .ant-form-item-control {
  width: 100%;
}

.raffleDatePicker .ant-form-item-control {
  grid-column: 2/-1;
}

.withSwitchers .ant-form-item-control-input-content {
  gap: 10px;
  display: flex;
}
#createRaffle .ant-form-item-label {
  text-align: left;
}

#createRaffle .ant-form-item-label > label {
  display: initial;
  align-items: flex-start;
  max-width: inherit;
  height: auto;
  text-align: end;
  white-space: initial;
}

.ant-upload-picture-card-wrapper {
  width: auto !important;
}

#createBanner .ant-form-item-row {
  display: grid;
  grid-template-columns: 1fr 75%;
  place-items: self-start;
  margin-bottom: 16px;
}
#createBanner .ant-form-item-control {
  width: 100%;
}
#gameBannerButton .ant-form-item-row {
  grid-template-columns: 1fr;
}
#regionsEdit .ant-form-item-control-input-content {
  width: 600px;
}
