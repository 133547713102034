.flex {
  display: flex;
  gap: 10px;
  margin-bottom: 14px;
}

.flex > span > div {
  width: 470px !important;
  height: 58px !important;
  margin-top: 10px;
}

.platforms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
}
.platforms span {
  padding-right: 5px;
  padding-left: 0;
}
