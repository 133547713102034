.icon {
  width: 35px;
  height: 35px;
}

.containerSearch {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 1rem;
}
