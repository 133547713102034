.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.textarea textarea {
  width: 100%;
  height: 64px;
  resize: none;
  font-size: 14px;
}
